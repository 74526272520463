import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router"; // Use this to track navigation changes

const Preloader = () => {
  const location = useLocation(); // Detect route changes
  const [once, setOnce] = useState(false);
  const preloader = useRef(null);

  useEffect(() => {
    if (location == "/") {
      setOnce(false);
    }

    const timeout = setTimeout(() => {

      if (preloader.current) {

        preloader.current.style.opacity = '0';
        preloader.current.style.transition = 'opacity 0.5s ease';

        setTimeout(() => {
          preloader.current.style.display = 'none';
          setOnce(true);
        }, 500);
      }

    }, 2000);

    return () => clearTimeout(timeout);
  }, [location.pathname]); // Trigger effect on route change

  if (once || location.pathname == "/" || location.pathname == "/login/" || location.pathname == "/register/") return null; // Hide preloader when not loading

  return (
    <div ref={preloader} className="fixed inset-0 flex items-center justify-center bg-white z-[999999999] transition-opacity duration-500" id="preloader">
      <div className="flex items-center justify-center animate-spin rounded-full w-[85px] h-[85px] bg-gradient-to-r from-[#24da78] to-[#0d6aee]">
        <div className="w-[80%] h-[80%] rounded-full bg-white"></div>
      </div>
    </div>
  );
};

export default Preloader;