import { useState, useEffect } from 'react';

const useUser = (storageType = 'localStorage') => {
  // Initialize state with null user
  const [user, setUser] = useState(null);

  // Determine if the code is running in a browser environment
  const isBrowser = typeof window !== 'undefined';

  // Determine which storage to use (localStorage or sessionStorage)
  const storage = isBrowser
    ? storageType === 'sessionStorage'
      ? sessionStorage
      : localStorage
    : null;

  // Initialize user from storage on mount
  useEffect(() => {
    if (isBrowser) {
      try {
        const savedUser = storage.getItem('user');
        setUser(savedUser ? JSON.parse(savedUser) : null);
      } catch (error) {
        console.error('Failed to read user from storage:', error);
      }
    }
  }, [isBrowser, storage]);

  // Init user
  const initUser = () => {
    if (isBrowser) {
      try {
        const savedUser = storage.getItem('user');
        setUser(savedUser ? JSON.parse(savedUser) : null);
      } catch (error) {
        console.error('Failed to read user from storage:', error);
      }
    }
  }

  // Update the user in state and storage
  const updateUser = (newUser) => {
    if (isBrowser) {
      try {
        setUser(newUser);
        storage.setItem('user', JSON.stringify(newUser));
      } catch (error) {
        console.error('Failed to update user in storage:', error);
      }
    }
  };

  // Clear the user from state and storage
  const clearUser = () => {
    if (isBrowser) {
      try {
        setUser(null);
        storage.removeItem('user');
      } catch (error) {
        console.error('Failed to clear user from storage:', error);
      }
    }
  };

  const setCrispUser = () => {
    if (isBrowser) {
      window.$crisp.push(["do", "session:reset"])
      initUser();
      if (user) {
        window.$crisp.push(["set", "user:email", [user.email]]);
      }
    }
  }

  const clearCrispUser = () => {
    if (isBrowser) {
      if (user) {
        window.$crisp.push(["do", "session:reset"])
      }
    }
  }

  // Sync state with storage changes (e.g., from other tabs)
  useEffect(() => {
    if (isBrowser) {
      const handleStorageChange = (event) => {
        if (event.key === 'user') {
          try {
            const newUser = event.newValue ? JSON.parse(event.newValue) : null;
            setUser(newUser);
          } catch (error) {
            console.error('Failed to sync user from storage:', error);
          }
        }
      };

      window.addEventListener('storage', handleStorageChange);
      return () => window.removeEventListener('storage', handleStorageChange);
    }
  }, [isBrowser]);

  return { user, updateUser, clearUser, setCrispUser, clearCrispUser };
};

export default useUser;