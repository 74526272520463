import useUser from "@/utils/user"
import { Script } from "gatsby"
import React from "react"

const CrispChat = () => {
  const { user } = useUser()
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `
            window.$crisp=[];
            window.CRISP_WEBSITE_ID="0459a465-95e1-46bf-b97e-1eaea118a7ec";
            (function(){
            d=document;s=d.createElement("script");
            s.src="https://client.crisp.chat/l.js";
            s.async=1;
            d.getElementsByTagName("head")[0].appendChild(s);
            })();

            ${
              user
                ? `window.CRISP_READY_TRIGGER = function() {
              
                  $crisp.push(["set", "user:email", ['${user.email}']]);
              }`
                : ""
            }
            `,
      }}
    />
  )
}

export default CrispChat
