/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css";
import React from "react";
import Preloader from "./src/components/Preloader";
import CrispChat from "@/components/CrispChat"

export const wrapPageElement = ({ element }) => (
    <>
        <CrispChat />
        <Preloader />
        {element}
    </>
);